import React from "react";
import styles from "./styles.module.css";
import Button from "../../components/Button";
import getweb_lboard2 from "../../../../assets/img/getweb_lboard2.gif";
import hosting from "../../../../assets/img/hosting.gif";
import design from "../../../../assets/img/design.gif";
import promotion from "../../../../assets/img/promotion.gif";
import commerce from "../../../../assets/img/commerce.gif";
import serveurs from "../../../../assets/img/serveurs.gif";
import courriels from "../../../../assets/img/courriels.gif";

export default function ServicesWeb() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Services Web</h1>
        <p>
          Un site bien conçu et convivial peut faire la différence entre 20 ou
          20 000 clients par mois. Chez Deluxe, nous nous concentrons sur une
          chose : la convivialité. Nous vous aiderons à assurer à votre
          entreprise la présence Internet qui garantira sa réussite et, plus
          important encore, sa croissance en ligne. Deluxe offre un vaste
          éventail de solutions de conception Web à des tarifs abordables. Que
          vous possédiez une petite entreprise et cherchiez à monter votre
          premier site Web ou exploitiez une grande société et désiriez étendre
          votre offre en ligne, nous avons pour vous une solution adaptée à vos
          besoins.
        </p>
        <img
          className={styles.banner}
          src={getweb_lboard2}
          alt="Besoin de cliquer avec plus clients? GetWeb c'est ici!"
        ></img>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2>Conception de sites Web</h2>
            <img src={design} alt="Conception de sites Web" />
            <p>
              Un site bien conçu et convivial peut faire la différence entre 20
              ou 20 000 clients par mois. Chez Deluxe, nous nous concentrons sur
              une chose : la convivialité.
            </p>
            <div className={styles.button_wrapper}>
              <Button
                text={"D'INFO"}
                url={"/developpez/services-web/conception-de-sites-web/"}
                minWidth={"200px"}
              />
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2>Forfaits courriels</h2>
            <img src={courriels} alt="@ symbole" />
            <p>
              Deluxe offre des services professionnels de courriel,
              d'hébergement et de conception graphique Web qui vous permettront
              de maximiser les efforts que vous déployez & à promouvoir votre
              site.
            </p>
            <div className={styles.button_wrapper}>
              <Button
                text={"D'INFO"}
                url={"/developpez/services-web/forfaits-courriels/"}
                minWidth={"200px"}
              />
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2>Hébergement Web</h2>
            <img src={hosting} alt="Http avec curseur" />
            <p>
              Deluxe Canada offre maintenant aux entrepreneurs et propriétaires
              de petits commerces canadiens des services d'hébergement Web
              Classe Affaires à prix très compétitifs.
            </p>
            <div className={styles.button_wrapper}>
              <Button
                text={"D'INFO"}
                url={"/developpez/services-web/hebergement-web/"}
                minWidth={"200px"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2>Promotion de site Web</h2>
            <img
              src={promotion}
              alt="Graphique à barres avec flèche pointant vers le haut"
            />
            <p>
              Deluxe offre des services professionnels de courriel,
              d'hébergement et de conception graphique Web qui vous permettront
              de maximiser les efforts que vous déployez & à promouvoir votre
              site.
            </p>
            <div className={styles.button_wrapper}>
              <Button
                text={"D'INFO"}
                url={"/developpez/services-web/promotion-de-site-web/"}
                minWidth={"200px"}
              />
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2>Serveurs virtuels infogérés</h2>
            <img src={serveurs} alt="Les serveurs" />
            <p>
              Un site bien conçu et convivial peut faire la différence entre 20
              ou 20 000 clients par mois. Chez Deluxe, nous nous concentrons sur
              une chose : la convivialité.
            </p>
            <div className={styles.button_wrapper}>
              <Button
                text={"D'INFO"}
                url={"/developpez/services-web/serveurs-virtuels-infogeres/"}
                minWidth={"200px"}
              />
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2>Solutions pour le cybercommerce</h2>
            <img src={commerce} alt="Femme sur ordinateur portable" />
            <p>
              Deluxe Canada offre maintenant aux entrepreneurs et propriétaires
              de petits commerces canadiens des services d'hébergement Web
              Classe Affaires à prix très compétitifs.
            </p>
            <div className={styles.button_wrapper}>
              <Button
                text={"D'INFO"}
                url={
                  "/developpez/services-web/solutions-pour-le-cybercommerce/"
                }
                minWidth={"200px"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
