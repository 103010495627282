import React from "react";
import Layout from "../../../other/layout";
import ServicesWeb from "../../../components/AC-StaticPages/Developpez/ServicesWeb/ServicesWeb";
import SEO from "../../../other/seo";

export default function servicesWeb() {
  return (
    <Layout>
      <SEO title="Services Web" />

      <ServicesWeb />
    </Layout>
  );
}
